import React, { useState } from 'react';

function App() {
  const [formData, setFormData] = useState({
    tenantNames: '',
    tenantEmail: '',
    checkInDate: '',
    checkOutDate: '',
    rentalAmount: '',
    securityDeposit: '',
    agreementDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ formData }),
      });

      const data = await response.json();
      if (response.ok) {
        alert('Emails sent successfully!');
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email');
    }
  };

  return (
    <div>
      <h1>Rental Agreement Form</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="tenantNames"
          placeholder="Tenant Name"
          value={formData.tenantNames}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="tenantEmail"
          placeholder="Tenant Email"
          value={formData.tenantEmail}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="checkInDate"
          placeholder="Check-in Date"
          value={formData.checkInDate}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="checkOutDate"
          placeholder="Check-out Date"
          value={formData.checkOutDate}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="rentalAmount"
          placeholder="Rental Amount"
          value={formData.rentalAmount}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="securityDeposit"
          placeholder="Security Deposit"
          value={formData.securityDeposit}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="agreementDate"
          placeholder="Agreement Date"
          value={formData.agreementDate}
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default App;
